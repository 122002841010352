@import 'utilities/typography.scss';

body {

  margin: 0;
  padding: 0;
  font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
  --dark-blue-grey:#122744;
  --slate: #48556a;
  --steel: #6f7d95;
  --deep-sky-blue: #0176fe;
  --ice-blue: #f4f8f9;
  --turquoise-green: #00dd73;
  --light-sky-blue: #d9e4eb;
  --leght-blue: #8ec6ff;
  --light-blue-1: rgba(142, 198, 255, 0.1);
  --medium-pink: #f66292;
  --salmon: #ff8064;
  --red-pink: #f72b57;
}