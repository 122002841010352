.header {
  background-color: #F4F8F9;
  padding: 20px;
  height: 100%;
}

.right {
  padding: 20px;
}

.logo {
  border-bottom: 1px #ccc solid;
  margin-bottom: 20px;
}

.help {
  min-height: 70px;
  text-align: right;
}

.pdf {
  height: 100%;
}

.footer{
  background: black;
}

@media (max-width: 575.98px) { 
  .pdf {
    height: 80%;
  }
}

.trouble {
  font-weight: bold;
  font-size: 12px;
}
.gethelp {
  color: #0069D9;
  font-weight: bold;
  font-size: 12px;
}