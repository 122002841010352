.labelError {
  margin-top: 2px;
  color: red;
}

.terms_and_conditions {
  display: flex;
}

.btn {
  background-color: #0276FE;
  width: 100%;
}