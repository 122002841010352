.alert-flash {
  color: white;
  border-radius: 10px;
  position: fixed;
  top: 50px;
  right: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  z-index: 1111;
}
.alert-flash  p {
  margin: 0;
}
.alert-flash-error {
  background: lightcoral;
}
.alert-flash-success {
  background: lightgreen;
}
.close-flash {
  color: white;
  cursor: pointer;
  margin-right: 10px;
}
