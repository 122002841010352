html { height:100%; }
body { height:100%; }
#root { height:100%; }
.row { height:100%; }

.App {
  
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row::after {
  content: "";
  clear: both;
  display: flex;
}

[class*="col-"] {
  float: left;
  padding: 15px;
}

* {
  box-sizing: border-box;
}

[class*="col-"] {
  width: 100%;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .col-s-1 {width: 8.33%;}
  .col-s-2 {width: 16.66%;}
  .col-s-3 {width: 25%;}
  .col-s-4 {width: 33.33%;}
  .col-s-5 {width: 41.66%;}
  .col-s-6 {width: 50%;}
  .col-s-7 {width: 58.33%;}
  .col-s-8 {width: 66.66%;}
  .col-s-9 {width: 75%;}
  .col-s-10 {width: 83.33%;}
  .col-s-11 {width: 91.66%;}
  .col-s-12 {width: 100%;}

  .steps {   
    padding: 0 70px 0 70px;
  }

  
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}

  .steps {   
    padding: 0 3% 0 3%;
  }
}


input[type="email"],
input[type="tel"],
input[type="password"],
input[type="text"],
input[type="number"] {
  width: 100%;
  height: 42px;
  padding: 6px 12px;
  border: 1px solid #d9e3eb;
  border-radius: 4px;
  box-shadow: none;
  font: normal 14px / 1.4 'Proxima Nova', Helvetica, Arial, sans-serif;
  transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  margin-bottom: 1rem;
}

input:focus {
  outline: 0 none;
  border-color:#0176fe;
  box-shadow: 0 1px 1px rgba(32, 205, 209, .075) inset, 0 0 2px rgba(32, 205, 209, .65);
}