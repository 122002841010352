// Nimbo Custom Font
@font-face {
  font-family: 'nimbo';
  src: url('../assets/fonts/nimbo.eot');
  src: url('../assets/fonts/nimbo.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/nimbo.ttf') format('truetype'),
       url('../assets/fonts/nimbo.woff') format('woff'),
       url('../assets/fonts/nimbo.svg#nimbo') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Proxima Nova
@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Regular.eot');
  src: url('../assets/fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('../assets/fonts/ProximaNova-Regular.woff') format('woff'),
    url('../assets/fonts/ProximaNova-Regular.ttf') format('truetype'),
    url('../assets/fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Semibold.eot');
  src: url('../assets/fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/ProximaNova-Semibold.woff2') format('woff2'),
    url('../assets/fonts/ProximaNova-Semibold.woff') format('woff'),
    url('../assets/fonts/ProximaNova-Semibold.ttf') format('truetype'),
    url('../assets/fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Light.eot');
  src: url('../assets/fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),    
    url('../assets/fonts/ProximaNova-Light.woff') format('woff'),
    url('../assets/fonts/ProximaNova-Light.ttf') format('truetype'),
    url('../assets/fonts/ProximaNova-Light.svg#ProximaNova-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

// Dancing Script
@font-face {
  font-family: 'Dancing Script';
  src: url('../assets/fonts/DancingScript-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dancing Script';
  src: url('../assets/fonts/DancingScript-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

$font-stack: 'Proxima Nova', Helvetica, Arial, sans-serif;
$font-default: Helvetica, Arial, sans-serif;
